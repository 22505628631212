import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {TranslateService, LangChangeEvent} from '@ngx-translate/core';

import * as sl from '../assets/i18n/sl.json';
import * as en from '../assets/i18n/en.json';
import * as de from '../assets/i18n/de.json';
import {marker} from '@biesbjerg/ngx-translate-extract-marker';
import {isPlatformBrowser} from '@angular/common';

const languageKey = 'language';

@Injectable()
export class I18nService {

  defaultLanguage: string;
  supportedLanguages: string[];
  supportedLanguageNames: string[];

  constructor(private translateService: TranslateService,
              @Inject(PLATFORM_ID) private platformId: object) {
    translateService.setTranslation(marker('en-US'), (en as any).default);
    translateService.setTranslation(marker('sl-SI'), (sl as any).default);
    translateService.setTranslation(marker('de-DE'), (de as any).default);
  }

  init(defaultLanguage: string, supportedLanguages: string[]) {
    this.defaultLanguage = defaultLanguage;
    this.supportedLanguages = supportedLanguages;
    this.supportedLanguageNames = supportedLanguages.map(l => l.split('-')[0]);
    this.setLanguage = '';

    this.translateService.onLangChange
      .subscribe((event: LangChangeEvent) => {
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem(languageKey, event.lang);
        }
      });
  }

  set setLanguage(language: string) {
    let languageStorageKeyValue = '';
    if (isPlatformBrowser(this.platformId)) {
      languageStorageKeyValue = localStorage.getItem(languageKey);
    }
    language = language || languageStorageKeyValue || this.translateService.getBrowserCultureLang();
    let isSupportedLanguage = this.supportedLanguages.includes(language);

    if (language && !isSupportedLanguage) {
      language = language.split('-')[0];
      language = this.supportedLanguages.find(supportedLanguage => supportedLanguage.startsWith(language)) || '';
      isSupportedLanguage = Boolean(language);
    }

    if (!isSupportedLanguage) {
      language = this.defaultLanguage;
    }

    this.translateService.use(language);
  }

	set setLanguageByName(languageName: string) {
		const languageCode = this.supportedLanguages.find(l => l.toLowerCase().startsWith(languageName.toLowerCase()));
		if (languageCode) {
			this.setLanguage = languageCode;
		}
	}

  get language(): string {
    return this.translateService.currentLang;
  }

  get languageName(): string {
    if (this.language.length >= 2) {
      return this.language.substr(0, 2);
    }
    // Default to eng
    return 'en';
  }

}
