import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {I18nService} from '../services/i18n.service';

@Injectable({
	providedIn: 'root'
})
export class LanguageGuard implements CanActivate {
	constructor(private i18nService: I18nService, private router: Router) {
	}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const {params} = next;
		if (params && params.language && this.i18nService.supportedLanguageNames.includes(params.language)) {
			this.i18nService.setLanguageByName = params.language;
			this.router.navigateByUrl(state.url.replace(`/${params.language}`, ''));
			return false;
		}
		return true;
	}
}
