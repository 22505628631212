import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTE_PATH } from '@common/types/route-path.enum';
import { LanguageGuard } from './app-routing.guard';

const routes: Routes = [
	{
		path: ROUTE_PATH.ROOT,
		loadChildren: () =>
			import('./pages/home-page/home-page.module').then(
				(m) => m.HomePageModule
			),
	},
	{
		path: ROUTE_PATH.APPLICATION,
		loadChildren: () =>
			import('./pages/application-page/application-page.module').then(
				(m) => m.ApplicationPageModule
			),
	},
	{
		path: ROUTE_PATH.HOW_IT_WORKS,
		loadChildren: () =>
			import('./pages/how-it-works-page/how-it-works-page.module').then(
				(m) => m.HowItWorksPageModule
			),
	},
	{
		path: ROUTE_PATH.OFFER,
		loadChildren: () =>
			import('./pages/offer-page/offer-page.module').then(
				(m) => m.OfferPageModule
			),
	},
	{
		path: ROUTE_PATH.PARTNERS,
		loadChildren: () =>
			import('./pages/partners-page/partners-page.module').then(
				(m) => m.PartnersPageModule
			),
	},
	{
		path: ROUTE_PATH.CONTACT,
		loadChildren: () =>
			import('./pages/contact-page/contact-page.module').then(
				(m) => m.ContactPageModule
			),
	},
	{
		path: ROUTE_PATH.LEGAL,
		loadChildren: () =>
			import('./pages/legal-page/legal-page.module').then(
				(m) => m.LegalPageModule
			),
	},
	{
		path: ROUTE_PATH.APPS,
		loadChildren: () =>
			import('./pages/app-versions-page/app-versions-page.module').then(
				(m) => m.AppVersionsPageModule
			),
	},
];

const languageRoutes: Routes = [
	...routes,
	{
		path: ':language',
		children: routes,
		canActivate: [LanguageGuard],
	},
	{
		path: '**',
		redirectTo: '',
		pathMatch: 'full',
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(languageRoutes, {
			initialNavigation: 'enabled',
			onSameUrlNavigation: 'reload',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
