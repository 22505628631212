import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError, map, shareReplay } from "rxjs/operators";
import { format } from "date-fns";
export class TrackVersionsDto {
	store: string;
	platform: string;
	app: string;
	name: string;
	source: string;
	id: string;
	errors?: string[];
	refreshed?: string;
	tracks: {
		track: string;
		state: string;
		versionCode: string;
		versionName: string;
	}[];
}

export class TrackVersionsTable {
	index: number;
	source: string;
	stores: {
		index: number;
		store: string;
		apps: {
			id: string;
			index: number;
			name: string;
			app: string;
			buttonDisabled: boolean;
			networkError: boolean;
			errors?: string[];
			refreshed?: string;
			tracks: {
				index: number;
				track: string;
				versions: {
					index: number;
					versionName: string;
					versionCode: string;
					state: string;
					subTrack?: string;
				}[];
			}[];
		}[];
	}[];
}

@Injectable()
export class StatusTrackerService {
	private VERSIONS_URL =
		"https://smartheating-status-tracker-fa-stg.azurewebsites.net/api/track/versions";

	private readonly trackVersionsTableSubject: BehaviorSubject<
		TrackVersionsTable[]
	> = new BehaviorSubject<TrackVersionsTable[]>([]);

	private readonly networkErrorSubject: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	private readonly loadingSubject: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	constructor(private http: HttpClient) {}

	get trackVersionsTable$(): Observable<TrackVersionsTable[]> {
		return this.trackVersionsTableSubject.asObservable();
	}

	get networkError$(): Observable<boolean> {
		return this.networkErrorSubject.asObservable();
	}

	get loading$(): Observable<boolean> {
		return this.loadingSubject.asObservable();
	}

	getAppVersions(instant?: boolean): Promise<void> {
		this.networkErrorSubject.next(false);
		this.loadingSubject.next(true);

		const fetchInstantly = instant != undefined && instant;
		return this.http
			.get(this.VERSIONS_URL + (fetchInstantly ? "?instant=true" : ""), {
				headers: new HttpHeaders({ timeout: `${30000}` }),
			})
			.pipe(
				map((data: TrackVersionsDto[]) => {
					this.trackVersionsTableSubject.next(this.convertToTableData(data));
					this.loadingSubject.next(false);
					if (fetchInstantly) {
						this.getAppVersions();
					}
				}),
				shareReplay(),
				catchError((err) => {
					this.networkErrorSubject.next(true);
					this.loadingSubject.next(false);
					console.error(err);
					return [];
				})
			)
			.toPromise();
	}

	getAppVersion(
		sourceIndex: number,
		storeIndex: number,
		app: string,
		store: string
	): Promise<void> {
		store = store.split(" ")[0].toLowerCase();
		let currentData = this.trackVersionsTableSubject.getValue();
		const appIndex = currentData[sourceIndex].stores[storeIndex].apps.findIndex(
			(x) => x.app == app
		);
		currentData[sourceIndex].stores[storeIndex].apps[appIndex].buttonDisabled =
			true;
		currentData[sourceIndex].stores[storeIndex].apps[appIndex].networkError =
			false;
		this.trackVersionsTableSubject.next(currentData);

		return this.http
			.get(this.VERSIONS_URL + `?application=${app}&store=${store}`)
			.pipe(
				map((data: TrackVersionsDto[]) => {
					currentData[sourceIndex].stores[storeIndex].apps[appIndex].tracks =
						this.convertToTableData(data)[0].stores[0].apps[0].tracks;
					currentData[sourceIndex].stores[storeIndex].apps[appIndex].refreshed =
						this.convertToTableData(data)[0].stores[0].apps[0].refreshed;
					currentData[sourceIndex].stores[storeIndex].apps[
						appIndex
					].buttonDisabled = false;
					this.trackVersionsTableSubject.next(currentData);
				}),
				shareReplay(),
				catchError((err) => {
					currentData[sourceIndex].stores[storeIndex].apps[
						appIndex
					].buttonDisabled = false;
					currentData[sourceIndex].stores[storeIndex].apps[
						appIndex
					].networkError = true;
					this.trackVersionsTableSubject.next(currentData);

					console.error(err);
					return [];
				})
			)
			.toPromise();
	}

	private convertToTableData(data: TrackVersionsDto[]): TrackVersionsTable[] {
		let table: TrackVersionsTable[] = [];
		const sources = [...new Set(data.map((obj) => obj.source))];
		for (const source of sources) {
			const appStores = data.filter((x) => x.source == source);
			table.push({
				index: this.getSourceIndex(source),
				source: source,
				stores: this.extractStores(appStores),
			});
		}
		return table.sort((a, b) => a.index - b.index);
	}

	private extractStores(appStores: TrackVersionsDto[]) {
		let storesTable = [];
		const stores = [...new Set(appStores.map((obj) => obj.store))];
		for (const store of stores) {
			storesTable.push({
				index: this.getStoreIndex(store),
				store: this.getStoreName(store),
				apps: this.extractApps(appStores, store),
			});
		}
		return storesTable.sort((a, b) => a.index - b.index);
	}

	private extractApps(appStores: TrackVersionsDto[], store: string) {
		const apps = appStores.filter((x) => x.store == store);
		let appsTable = [];
		for (const app of apps) {
			appsTable.push({
				index: this.getAppIndex(app.app),
				name: app.name,
				app: app.app,
				id: app.id,
				buttonDisabled: false,
				networkError: false,
				errors: app.errors,
				refreshed: app.refreshed
					? format(new Date(app.refreshed), "dd.MM.yyyy HH:mm:ss")
					: undefined,
				tracks: this.extractTracks(app),
			});
		}
		return appsTable.sort((a, b) => a.index - b.index);
	}

	private extractTracks(app: TrackVersionsDto) {
		const tracksTable = [];
		const tracks = [...new Set(app.tracks.map((obj) => obj.track))];
		if (app.store == "google")
			tracksTable.push({
				index: this.getTrackIndex("Closed Testing"),
				track: "Closed Testing",
				versions: [],
			});
		for (const track of tracks) {
			const versionsTable = this.extractVersions(app, track);
			if (app.store == "google" && this.isClosedTesting(track)) {
				tracksTable[0].versions = tracksTable[0].versions.concat(versionsTable);
				tracksTable[0].versions.sort((a, b) => a.index - b.index);
			} else {
				tracksTable.push({
					index: this.getTrackIndex(track),
					track: this.capitalize(track),
					versions: versionsTable,
				});
			}
		}
		return tracksTable.sort((a, b) => a.index - b.index);
	}

	private extractVersions(app: TrackVersionsDto, track: string) {
		const versionsTable = [];
		const versions = app.tracks.filter((x) => x.track == track);
		for (const version of versions)
			versionsTable.push({
				index: this.getVersionIndex(
					app.store == "google" ? track : version.state
				),
				versionCode: version.versionCode,
				versionName: version.versionName,
				state: version.state,
				subTrack: this.getSubTrack(app.store, version.state, track),
			});
		return versionsTable.sort((a, b) => a.index - b.index);
	}

	private isClosedTesting(track: string): boolean {
		return track != "production" && track != "internal";
	}

	private getSubTrack(store: string, state: string, track: string): string {
		if (this.isClosedTesting(track) && store == "google")
			return this.capitalize(track);
		if (store == "apple" || store == "huawei") return state;
		return undefined;
	}

	private getStoreName(store: string): string {
		switch (store) {
			case "google":
				return "Google Play Console";
			case "apple":
				return "Apple App Store";
			case "huawei":
				return "Huawei App Gallery";
			default:
				return this.capitalize(store);
		}
	}

	private getSourceIndex(source: string): number {
		switch (source) {
			case "Clausius":
				return 0;
			case "ClausiusBT":
				return 1;
			case "Kelvin":
				return 2;
		}
	}

	private getStoreIndex(store: string): number {
		switch (store.toLowerCase()) {
			case "google":
				return 0;
			case "apple":
				return 1;
			case "huawei":
				return 2;
			case "web":
				return 3;
		}
	}

	private getAppIndex(app: string): number {
		const appIndexes = [
			{ applications: ["clausius", "clausiusbt", "kelvin"], index: 0 },
			{ applications: ["heatnext", "heatnextbt", "heatnextpro"], index: 1 },
			{ applications: ["heathome", "heathomedirect"], index: 2 },
		];
		const index = appIndexes.findIndex((item) =>
			item.applications.includes(app.toLowerCase())
		);
		return index < 0 ? 3 : appIndexes[index].index;
	}

	private getTrackIndex(track: string): number {
		const appIndexes = [
			{ applications: ["appconnect", "production"], index: 0 },
			{ applications: ["internal", "testflight"], index: 1 },
		];
		const index = appIndexes.findIndex((item) =>
			item.applications.includes(track.toLowerCase())
		);
		return index < 0 ? 2 : appIndexes[index].index;
	}

	private getVersionIndex(version: string): number {
		const appIndexes = [
			{ applications: ["alpha", "ready_for_sale"], index: 0 },
			{ applications: ["beta"], index: 1 },
		];
		const index = appIndexes.findIndex((item) =>
			item.applications.includes(version.toLowerCase())
		);
		return index < 0 ? 2 : appIndexes[index].index;
	}

	private capitalize(word: string) {
		return word[0].toUpperCase() + word.slice(1);
	}
}
