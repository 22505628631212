import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { getRouterLink, ROUTE_PATH } from '@common/types/route-path.enum';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, timer } from 'rxjs';
import { filter, first, map, startWith, switchMap, tap } from 'rxjs/operators';
import { environment } from '../environments/environment.prod';
import { I18nService } from '../services/i18n.service';
import { StatusCheckerService } from '../services/status-checker.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	appInsights: ApplicationInsights;
	constructor(
		private titleService: Title,
		private i18nService: I18nService,
		private route: ActivatedRoute,
		private router: Router,
		private statusCheckerService: StatusCheckerService,
		private translateService: TranslateService,
		@Inject(PLATFORM_ID) private platformId: object
	) {
		if (isPlatformBrowser(this.platformId)) {
			if (environment.production) {
				this.appInsights = new ApplicationInsights({
					config: {
						instrumentationKey: environment.instrumentationKey,
						enableAutoRouteTracking: true,
					},
				});

				this.appInsights.loadAppInsights();
			}
			timer(0, 60000)
				.pipe(
					switchMap(() => this.statusCheckerService.getCurrentStatusEvents())
				)
				.subscribe((statusEvents) =>
					this.statusCheckerService.currentStatusEventsSubject.next(
						statusEvents
					)
				);
		}
	}

	ngOnInit(): void {
		this.route.queryParams
			.pipe(
				first((params) => params.lng),
				tap((params) => {
					this.i18nService.setLanguageByName = params.lng;
				})
			)
			.subscribe();

		combineLatest([
			this.router.events.pipe(
				filter((evt) => evt instanceof NavigationEnd),
				map((evt: NavigationEnd) => evt.url)
			),
			this.translateService.onLangChange.pipe(
				startWith(this.translateService.currentLang)
			),
		]).subscribe(([url, lang]) => {
			if (url === getRouterLink(ROUTE_PATH.ROOT)) {
				this.titleService.setTitle('SeltronHome');
			} else if (url === getRouterLink(ROUTE_PATH.APPLICATION)) {
				this.titleService.setTitle(
					'SeltronHome - ' + this.translateService.instant('Clausius App')
				);
			} else if (url === getRouterLink(ROUTE_PATH.HOW_IT_WORKS)) {
				this.titleService.setTitle(
					'SeltronHome - ' + this.translateService.instant('How it works')
				);
			} else if (url === getRouterLink(ROUTE_PATH.OFFER)) {
				this.titleService.setTitle(
					'SeltronHome - ' + this.translateService.instant('Offer')
				);
			} else if (url === getRouterLink(ROUTE_PATH.PARTNERS)) {
				this.titleService.setTitle(
					'SeltronHome - ' + this.translateService.instant('Become a partner')
				);
			} else if (url === getRouterLink(ROUTE_PATH.CONTACT)) {
				this.titleService.setTitle(
					'SeltronHome - ' +
						this.translateService.instant('Contact and support')
				);
			} else if (url === getRouterLink(ROUTE_PATH.STATUS)) {
				this.titleService.setTitle(
					'SeltronHome - ' + this.translateService.instant('Service status')
				);
			} else if (url.indexOf(getRouterLink(ROUTE_PATH.LEGAL)) > -1) {
				const queryParams = this.router.routerState.snapshot.root.queryParams;
				if (queryParams?.page) {
					if (queryParams.page === 'terms') {
						this.titleService.setTitle(
							'SeltronHome - ' +
								this.translateService.instant(
									'General terms and conditions for end users'
								)
						);
					} else if (queryParams.page === 'terms_partners') {
						this.titleService.setTitle(
							'SeltronHome - ' +
								this.translateService.instant(
									'General conditions for business users'
								)
						);
					} else if (queryParams.page === 'privacy') {
						this.titleService.setTitle(
							'SeltronHome - ' + this.translateService.instant('Privacy policy')
						);
					}
				}

				if (!lang.toString().startsWith(queryParams.lng)) {
					const selectedLang = lang['lang']
						? lang['lang'].toString().split('-')[0]
						: lang.toString().split('-')[0];
					const newUrl = url.replace(queryParams.lng, selectedLang);

					window.history.replaceState({}, '', newUrl);
				}
			}
			if (isPlatformBrowser(this.platformId)) {
				window.scrollTo(0, 0);
			}
		});
	}
}
