import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import { environment } from '../environments/environment.prod';
import { I18nService } from '../services/i18n.service';
import { StatusCheckerService } from '../services/status-checker.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StatusTrackerService } from 'src/services/status-tracker.service';

export function initLanguage(translateService: I18nService) {
	return () => translateService.init('en-US', ['sl-SI', 'en-US', 'de-DE']);
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		RouterModule,
		AppRoutingModule,
		TranslateModule.forRoot(),
		Angulartics2Module.forRoot({
			developerMode: !environment.production,
		}),
		HttpClientModule,
	],
	providers: [
		I18nService,
		StatusCheckerService,
		{
			provide: APP_INITIALIZER,
			useFactory: initLanguage,
			multi: true,
			deps: [I18nService],
		},
		StatusTrackerService,
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
