export enum ROUTE_PATH {
	ROOT = '',
	APPLICATION = 'application',
	HOW_IT_WORKS = 'how-it-works',
	OFFER = 'offer',
	PARTNERS = 'partners',
	CONTACT = 'contact',
	STATUS = 'status',
	LEGAL = 'legal',
	APPS = 'apps',
}

export const getRouterLink = (routePath: ROUTE_PATH) => {
	return `/${routePath}`;
};

export enum EXTERNAL_PATH {
	APP_GOOGLE_PLAY = 'https://play.google.com/store/apps/details?id=eu.seltron.clausius',
	APP_APPLE_STORE = 'https://itunes.apple.com/us/app/seltron-clausius/id894504880',
	SELTRON_PAGE = 'https://www.seltron.si',
	SELTRON_EU_HOME_PAGE = 'https://www.seltron.eu',
	SELTRON_HOME_COM = 'https://www.seltronhome.com',
	CLAUSIUS_SELTRONHOME = 'https://clausius.seltronhome.com',
	MAIL_TO_INFO_SELTRON_HOME = 'mailto:info@seltronhome.com',
	MAIL_TO_TECHNICAL_SUPPORT_SELTON_HOME = 'mailto:tehnicna.podpora@seltron.si',
	MAIL_TO_PODPORA_SELTRON_HOME = 'mailto:podpora@seltron.si',
	SELTRON_INSTALLERS = 'https://www.seltron.si/monterji/',
	HOW_TO_FIND_SELTRON = 'https://www.seltron.si/kje-smo/',
	AUTH_0_SECURITY = 'https://auth0.com/security',
	SELTRON_WHERE_TO_BUY_GWD = 'https://b2b.seltron.si/products/Regulacijska_tehnika_Seltron_22/SeltronHome_305/i_12987_komunikacijski-modul-seltron-gwd3e-ethernet',
}

const DATA_PREFIX = 'assets/data/';
export const ASSETS_PATH = {
	INSTRUCTIONS_GWD1_SLO: `${DATA_PREFIX}navodila.pdf`,
	INSTRUCTIONS_GWD2_SLO: `${DATA_PREFIX}GWD2_V2.0_SI.pdf`,
	INSTRUCTIONS_GWD3_SLO: `${DATA_PREFIX}GWD3_V1.4_SI.pdf`,
	INSTRUCTIONS_GWD1_EN: `${DATA_PREFIX}navodila_en.pdf`,
	INSTRUCTIONS_GWD2_EN: `${DATA_PREFIX}GWD2_V2.0_EN.pdf`,
	INSTRUCTIONS_GWD3_EN: `${DATA_PREFIX}GWD3_V1.4_EN.pdf`,
	INSTRUCTIONS_GWD1_DE: `${DATA_PREFIX}navodila_de.pdf`,
	INSTRUCTIONS_GWD2_DE: `${DATA_PREFIX}GWD2_V2.0_DE.pdf`,
	INSTRUCTIONS_GWD3_DE: `${DATA_PREFIX}GWD3_V1.4_DE.pdf`,
};

const STATUS_DATA_PREFIX = 'assets/status-data/';
export const STATUS_DATA = {
	DEFAULT: `${STATUS_DATA_PREFIX}status-data.json`,
};
