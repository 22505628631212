import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { STATUS_TRACKER_API } from "@common/types/constants";
import { STATUS_DATA } from "@common/types/route-path.enum";
import { StatusEvent } from "@common/types/types";
import { Observable, of, ReplaySubject } from "rxjs";
import { catchError, map } from "rxjs/operators";

export interface StatusDataItem {
	key: string;
	text: string;
}

interface GetStatusDataResponse {
	planned: StatusDataItem[];
	recent: StatusDataItem[];
}

@Injectable()
export class StatusCheckerService {
	private SPECIFICATIONS_URL = "https://api.seltronhome.com/api/specifications";
	currentStatusEventsSubject: ReplaySubject<StatusEvent[]> = new ReplaySubject<
		StatusEvent[]
	>(1);

	constructor(private http: HttpClient) {}

	get currentStatusEvents$(): Observable<StatusEvent[]> {
		return this.currentStatusEventsSubject.asObservable();
	}

	getStatusData(): Observable<GetStatusDataResponse> {
		return this.http.get(STATUS_DATA.DEFAULT).pipe(
			catchError((err) => {
				console.error(err);
				return of(null);
			})
		);
	}

	getSpecifications(): Observable<any[]> {
		return this.http.get(this.SPECIFICATIONS_URL).pipe(
			catchError((err) => {
				console.error(err);
				return of(null);
			})
		);
	}

	getCurrentStatusEvents(): Observable<StatusEvent[]> {
		const url = `${STATUS_TRACKER_API}/status?platform=seltronhome`;
		return this.http.get(url).pipe(
			map((statusEvents: StatusEvent[]) =>
				statusEvents?.filter(
					(event) =>
						new Date(event.startTime).getTime() < new Date().getTime() &&
						(!event.endTime ||
							(event.endTime &&
								new Date(event.endTime).getTime() > new Date().getTime()))
				)
			),
			catchError((err) => {
				console.error(err);
				return of(null);
			})
		);
	}

	getAllStatusEvents(): Observable<StatusEvent[]> {
		const url = `${STATUS_TRACKER_API}/statusevents?platform=seltronhome`;
		return this.http.get(url).pipe(
			catchError((err) => {
				console.error(err);
				return of(null);
			})
		);
	}
}
